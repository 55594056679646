<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!--산업안전보건위원회 회의록 기본정보-->
          <c-card title="LBL0010201" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <!--회의제목-->
                <c-text
                  required
                  :editable="editable"
                  :disabled="true"
                  label="LBL0010198"
                  name="proceedingsTitle"
                  v-model="opinions.proceedingsTitle">
                </c-text>
              </div>
              <!--회의일-->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="true"
                  label="LBL0010199"
                  name="proceedingsDate"
                  v-model="opinions.proceedingsDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="true"
                  type="edit"
                  name="plantCd"
                  v-model="opinions.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept
                  :required="true"
                  :disabled="true"
                  :editable="editable"
                  label="작성부서"
                  name="deptCd"
                  v-model="opinions.deptCd">
                </c-dept>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :required="true"
                  :disabled="true"
                  label="의견접수 마감일"
                  name="opinionDt"
                  v-model="opinions.opinionDt">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!--회의내용-->
                <c-textarea
                  :editable="editable"
                  :disabled="true"
                  :rows="2"
                  label="LBL0010202"
                  name="proceedingsContents"
                  v-model="opinions.proceedingsContents">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-table
                  ref="table"
                  title="참석자 의견"
                  :columns="grid.columns"
                  :data="opinions.opinionlist"
                  :gridHeight="gridHeight"
                  :merge="grid.merge"
                  :editable="editable&&!disabled"
                  :isExcelDown="false"
                  :filtering="false"
                  :columnSetting="false"
                >
                  <!-- @innerBtnClicked="innerBtnClicked" -->
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn 
                        v-if="editable && !isAttend" 
                        label="참석" 
                        icon="check"
                        @btnClicked="attendData"/>
                      <c-btn 
                        v-if="editable && !isAttend" 
                        label="의견저장" 
                        icon="save"
                        @btnClicked="updateAttendData"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
      <q-dialog v-model="dialogShow" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <!-- 서명란 -->
          <c-card title="LBL0000758" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 결재 -->
                <c-btn 
                  v-show="editable" 
                  label="참석" 
                  icon="save" 
                  @btnClicked="saveDialog"/>
                  <!-- 지우기 -->
                <c-btn label="LBL0000759" color="red" :showLoading="false" @btnClicked="eraseSignature" />
                <!-- 취소 -->
                <c-btn 
                  label="LBLCANCEL" 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="row">
                <div class="col-xs-12 col-sm-12">
                  <VueSignaturePad width="300px" height="300px" ref="signaturePad" />
                </div>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'proceedings-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        proceedingsId: '',
        proceedingStatusCd: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    contentHeight: null,
  },
  data() {
    return {
      dialogShow: false,
      attachable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OSH_PROCEEDINGS',
        taskKey: '',
      },
      saveUrl: transactionConfig.sai.osh.proceedings.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isApproval: false,
      isdelete: false,
      opinions: {
        proceedingsId: '',
        plantCd: '',
        proceedingsDate: '',
        proceedingsContents: '',
        proceedingsAttendeeId: '',
        proceedingsTitle: '',
        regUserId: '',
        chgUserId: '',
        deptCd: '',
        attendeeCoModels: [],
        attendeeEmpModels: [],
        deleteAttendeeCoModels: [],
        deleteAttendeeEmpModels: [],
        opinions: [],
        attendees: [],
        opinionlist: [],
        opinionDt: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      filter: '',
      subjectGrid: {
        merge: [
          { index: 0, colName: 'codeName' },
        ],
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '심의의결사항',
            align: 'left',
            sortable: true,
          },
          // {
          //   name: 'attrVal1',
          //   field: 'attrVal1',
          //   label: '회의항목 상세',
          //   align: 'left',
          //   sortable: true,
          // },
        ]
      },
      grid: {
        merge: [
          { index: 0, colName: 'deptName' },
          { index: 1, colName: 'regUserId' },
          { index: 2, colName: 'regUserId' },
          { index: 3, colName: 'proceedingOpinionCd' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '참석자',
            align: 'center',
            style: 'width: 200px',
            sortable: true,
          },
          {
            name: 'attendeeSignatureName',
            field: 'attendeeSignatureName',
            label: '참석여부',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'proceedingOpinionName1',
            field: 'proceedingOpinionName1',
            label: '심의의결사항',
            style: 'width: 300px',
            align: 'left',
            sortable: true,
          },
          // {
          //   name: 'proceedingOpinionName2',
          //   field: 'proceedingOpinionName2',
          //   label: '회의항목 상세',
          //   style: 'width: 300px',
          //   align: 'left',
          //   sortable: true,
          // },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            label: '참석자 의견',
            align: 'left',
            type: 'textarea',
            sortable: true,
          },
        ]
      },
      inGrid: {
        columns: [
          {
            name: 'attendeeDeptName',
            field: 'attendeeDeptName',
            //부서
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            //이름
            label: 'LBLNAME',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            //직책
            label: 'LBLPOSITION',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            //비고
            label: 'LBLREMARK',
            type: 'text',
            align: 'left',
            style: 'width: 200px',
          },
        ],
        height: '300px',
        data: [],
      },
    };
  },
  computed: {
    hasTargetDepts() {
      return this.opinions.attendees && this.opinions.attendees.length > 0
    },
    listCheck() {
      return this.opinions.attendeeEmpModels.length > 0 || this.opinions.attendeeCoModels.length > 0
    },
    disabled() {
      return !this.editable && this.data.proceedingStatusCd !== 'PSC0000002';
    },
    isOwner() {
      return this.$store.getters.user.deptCd == this.opinions.deptCd;
    },
    isAttend() {
      if (this.opinions.opinionlist.length > 0) {
        return this.opinions.opinionlist[0].attendeeSignatureName === '참석';
      } else {
        return false;
      }
    },
    gridHeight() {
      let height = 250;
      let _margin = 600;
      height = this.contentHeight - _margin
      if (height < 250) {
        height = 250
      }
      return String(height) + 'px';
    },
    showApproval() {
      return this.editable
        && !this.disabled
        && this.opinions.attendeeCoModels.length === this.$_.filter(this.opinions.attendeeCoModels, {
          attendeeSignatureFlag: 'Y'
        }).length
        && this.opinions.attendeeEmpModels.length === this.$_.filter(this.opinions.attendeeEmpModels, {
          attendeeSignatureFlag: 'Y'
        }).length
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.opinions.proceedingsDate = this.$comm.getToday();
      // url setting
      this.detailUrl = selectConfig.sai.osh.proceedings.get.url;
      this.insertUrl = transactionConfig.sai.osh.proceedings.insert.url;
      this.updateUrl = transactionConfig.sai.osh.proceedings.update.url;
      this.deleteUrl = transactionConfig.sai.osh.proceedings.delete.url;
      this.redirectUrl = selectConfig.com.mobileHostname.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.proceedingsId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.proceedingsId);
        this.$http.param = {userId: this.$store.getters.user.userId, isreg: 'Y'}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.opinions = _result.data;
        },);
      }
    },
    attendData() {
      this.dialogShow = true;
      setTimeout(this.setSignature, 500);
    },
    updateAttendData() {
      this.$http.url = transactionConfig.sai.osh.proceedings.update.opinion;
      this.$http.type = 'PUT';
      this.$http.param = this.opinions.opinionlist
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getDetail();
      },);
    },
    setSignature() {
      signaturePad = this.$refs.signaturePad
      this.$http.url = '/api/sys/appr/signature';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          signaturePad.fromDataURL(_result.data);
        }
      })
    },
    saveDialog() {
      let apprSignature = '';
      signaturePad = this.$refs.signaturePad
      if (signaturePad.saveSignature().isEmpty) {
        apprSignature = ''
      } else {
        apprSignature = signaturePad.saveSignature().data;
      }
      if (apprSignature) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: '참석하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sai.osh.proceedings.signature.url;
            this.$http.type = 'PUT';
            this.$http.param = {
              chgUserId: this.$store.getters.user.userId,
              proceedingsId: this.popupParam.proceedingsId,
              attendeeUserId: this.$store.getters.user.userId,
              opinions: this.opinions.opinionlist,
              electronSignature: apprSignature,
            }
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
              this.dialogShow = false;
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE ', /* 안내 */
          message: '서명이 없습니다.', /* 결재가 정상적으로 요청되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      }
    },
    closeDialog() {
      this.dialogShow = false;
    },
    eraseSignature() {
      signaturePad.clearSignature();
    },
  }
};
</script>
